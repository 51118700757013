<template>
  <v-card
    class="data-vcard"
    :loading="loadingMaterials"
  >
    <v-card-title
      class="mt-4"
    >
      <v-form
        ref="form"
        class="mt-2"
        style="width: 100%;"
        :disabled="loadingMaterials"
        @submit.prevent="searchMaterials"
      >
        <div
          class="d-flex align-center"
        >
          <span class="mr-4">Search for specific EPD</span>
          <v-spacer />
          <v-text-field
            v-model="search"
            label="Search"
            class="flex-grow-1"
            hide-details
            dense
          />
          <v-btn
            submit
            color="shamrock white--text"
            class="ml-4"
            :loading="loadingMaterials"
            @click="searchMaterials"
          >
            Search
          </v-btn>
          <v-btn
            class="ml-2"
            :disabled="loadingMaterials"
            @click="clearSearch"
          >
            Clear
          </v-btn>
        </div>
      </v-form>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="cleanedMaterials"
        item-key="id"
        single-select
        class="elevation-1 mt-4"
        fixed-header
        hide-default-footer
        disable-pagination
        @click:row="rowClick"
      >
        <template v-slot:item.edp="{ item }">
          <div class="d-flex align-center">
            <div
              style="cursor: pointer"
              @click.stop="() => openEDPInfoDialog[item.id] = true"
            >
              <v-icon
                small
                right
              >
                mdi-book-open-page-variant
              </v-icon>
            </div>
            <v-dialog
              v-model="openEDPInfoDialog[item.id]"
              max-width="500px"
            >
              <v-card>
                <v-card-title>EDP Value</v-card-title>
                <v-card-text>
                  <JsonPretty :data="item" />
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="primary"
                    text
                    @click="() => openEDPInfoDialog[item.id] = false"
                  >
                    Close
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </template>
        <template v-slot:header.gwp_per_category_declared_unit_fixed="{ header }">
          <div class="d-flex align-center">
            {{ header.text }}
            <div
              style="cursor: pointer"
              @click.stop="() => openGwpInfoDialog = true"
            >
              <v-icon
                small
                right
              >
                mdi-book-open-page-variant
              </v-icon>
            </div>
            <v-dialog
              v-model="openGwpInfoDialog"
              max-width="500px"
            >
              <v-card>
                <v-card-title>Uncertain Adjusted Warming Potential Information</v-card-title>
                <v-card-text>
                  <p>Uncertainty Adjusted Global Warming Potential (uaGWP) represents the upper range of GWP values based on statistical analysis of EPD data quality and specificity.</p>
                  <p>It provides a more conservative estimate that accounts for data uncertainty when comparing products, helping to make more confident low-carbon purchasing decisions.</p>
                  <p>
                    For more detailed information, you can visit the
                    <a
                      href="https://docs.buildingtransparency.org/methodology/uncertainty-and-statistics"
                      target="_blank"
                      class="text-decoration-none"
                    >
                      EC3 Uncertainty and Specificity Page
                    </a>.
                  </p>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="primary"
                    text
                    @click="() => openGwpInfoDialog = false"
                  >
                    Close
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </template>
        <!-- Display loading skeleton when scrolling downwards -->
        <template
          v-slot:[`body.append`]
        >
          <tr
            v-if="!scrollingLastPage"
            v-intersect.quiet="loadMore"
          >
            <td
              :colspan="headers.length"
              class="text-center"
            >
              <v-skeleton-loader
                :loading="true"
                type="table-row"
              />
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card-text>
    <!--    <v-card-text v-if="user.isAdmin">-->
    <!--      <v-expansion-panels>-->
    <!--        <v-expansion-panel>-->
    <!--          <v-expansion-panel-header>-->
    <!--            EC3 Data-->
    <!--          </v-expansion-panel-header>-->
    <!--          <v-expansion-panel-content>-->
    <!--            <JsonPretty :data="statistics" />-->
    <!--          </v-expansion-panel-content>-->
    <!--        </v-expansion-panel>-->
    <!--      </v-expansion-panels>-->
    <!--    </v-card-text>-->
    <v-card-actions class="py-4 px-5">
      <v-btn
        color="red"
        text
        @click="$emit('close')"
      >
        Cancel
      </v-btn>
      <v-spacer />
      <v-btn
        color="shamrock white--text"
        elevation="0"
        @click="$emit('returnToSearchParametersForm')"
      >
        Back
      </v-btn>
      <v-btn
        color="shamrock white--text"
        :disabled="!selectedMaterial"
        elevation="0"
        @click="customMaterial"
      >
        Create Element
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script lang="js">

import JsonPretty from 'vue-json-pretty'
import 'vue-json-pretty/lib/styles.css'
import { unitsFromEc3String } from '@/helpers/ec3Helpers'
import { createNamespacedHelpers, mapGetters as mapGlobalGetters } from 'vuex'

const { mapGetters: mapAuthGetters } = createNamespacedHelpers('auth')

export default {
  name: 'EC3SearchForSpecificEPD',
  components: {
    JsonPretty
  },
  props: {
    categories: {
      type: [Array, undefined],
      default: () => []
    },
    ec3SelectedCategory: {
      type: String,
      default: null
    },
    ec3SelectedSubcategory: {
      type: String,
      default: null
    },
    searchParameters: {
      type: Object,
      required: true
    },
    statistics: {
      type: Object,
      default: null
    }
  },
  data () {
    const itemsPerPage = 40
    return {
      search: null,
      materialQuery: this.searchParameters.materialQuery,
      cleanedMaterials: [],
      scrollingLastPage: false,
      scrollingEndIndex: 0,
      scrollingStartIndex: 0,
      loadingMaterials: false,
      selectedMaterial: null,
      openGwpInfoDialog: false,
      openEDPInfoDialog: {},
      itemsPerPage
    }
  },
  computed: {
    ...mapAuthGetters(['user']),
    ...mapGlobalGetters(['unitMappings', 'getEquivalentUnits', 'showSnackbar']),
    headers () {
      return [
        {
          text: 'Name',
          align: 'start',
          sortable: true,
          value: 'name'
        },
        {
          text: 'Manufacturer',
          align: 'start',
          sortable: true,
          value: 'manufacturer.name'
        },
        {
          text: 'Description',
          align: 'start',
          sortable: false,
          value: 'description'
        },
        {
          text: 'Declaration Type',
          align: 'start',
          sortable: true,
          value: 'declaration_type'
        },
        {
          text: 'uaGWP (kgCO₂e)',
          align: 'start',
          sortable: true,
          value: 'gwp_per_category_declared_unit_fixed'
        }
      ]
    },
    materialFilter () {
      return this.statistics?.materialFilter?.material_filter_str
    }
  },
  async created () {
    this.resetTable()
    return this.loadMore(null, null, true)
  },
  methods: {
    cleanMaterials (materials) {
      return materials?.map((material) => {
        return {
          ...material,
          compressive_strength_28d_fixed: this.cleanValue(material, 'compressive_strength_28d')
            ?.toFixed(2),
          gwp_per_category_declared_unit_fixed: this.cleanValue(material, 'gwp_per_category_declared_unit')
            ?.toFixed(1),
          density_fixed: this.cleanValue(material, 'density')
            ?.toFixed(0)
        }
      }) || []
    },
    async searchMaterials () {
      this.materialQuery = this.addQ(this.search)
      this.resetTable()
      return this.loadMore(null, null, true)
    },
    async clearSearch () {
      this.search = undefined
      this.materialQuery = this.addQ()
      this.resetTable()
      return this.loadMore(null, null, true)
    },
    resetTable () {
      this.cleanedMaterials = []
      this.scrollingLastPage = false
      this.scrollingEndIndex = 0
      this.scrollingStartIndex = 0
      this.loadingMaterials = false
    },
    addQ (query) {
      const materialFilter = { ...this.materialQuery.materialFilter, q__op: 'like', q__value: query }
      return {
        ...this.materialQuery,
        materialFilter
      }
    },
    async customMaterial () {
      this.$emit('newCustomMaterial', {
        statistics: { material: this.selectedMaterial },
        searchParameters: {
          ec3SelectedCategory: this.ec3SelectedCategory,
          ec3SelectedSubcategory: this.ec3SelectedSubcategory
        }
      })
    },
    cleanValue: function (obj, attr) {
      return unitsFromEc3String(obj[attr], this.unitMappings)?.value
    },
    async loadMore (entries, observer, isIntersecting) {
      if (isIntersecting && !this.loadingMaterials && !this.scrollingLastPage) {
        this.loadingMaterials = true
        const pageNumber = this.cleanedMaterials.length / this.itemsPerPage
        // Unless there is no more data, this should always be a whole number
        if (pageNumber % 1 === 0) {
          const numberFetched = await this.queryMaterials(pageNumber + 1, true)
          this.scrollingLastPage = numberFetched !== this.itemsPerPage
          this.cleanMaterials()
          const indexesLeft = this.cleanedMaterials.length - this.scrollingEndIndex
          if (indexesLeft < this.itemsPerPage) {
            this.scrollingStartIndex = this.scrollingEndIndex + indexesLeft - this.itemsPerPage
            this.scrollingEndIndex = this.cleanedMaterials.length
          } else {
            this.scrollingStartIndex += this.itemsPerPage
            this.scrollingEndIndex += this.itemsPerPage
          }
        }
        this.loadingMaterials = false
      }
    },
    async queryMaterials (pageNumber = 1, append = false) {
      const realPageNumber = pageNumber || 1
      this.loadingMaterials = true
      const query = (await this.$axios.post(`ec3/materials`, {
        pageSize: this.itemsPerPage,
        pageNumber: realPageNumber,
        query: this.materialQuery
      }))
      if (query.status === 200) {
        if (append) {
          this.cleanedMaterials = [...this.cleanedMaterials, ...this.cleanMaterials(query.data.response)]
        } else {
          this.cleanedMaterials = this.cleanMaterials(query.data.response)
        }
      } else {
        await this.showSnackbar({ color: 'error', text: 'Whoops, an error occurred while fetching from EC3. Please try again.' })
      }
      this.loadingMaterials = false
      return query.data?.response?.length || 0
    },
    rowClick: function (item, row) {
      row.select(true)
      this.selectedMaterial = item
    }
  }
}
</script>
<style scoped>
  ::v-deep tr.v-data-table__selected {
    background-color: var(--v-shamrock-base) !important;
  }
  .v-card.data-vcard {
    display: flex !important;
    flex-direction: column !important;
    height: 100vh;
  }

  ::v-deep .v-card__text {
    flex-grow: 1 !important;
    overflow: auto !important;
  }

</style>
