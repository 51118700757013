<template>
  <div
    class="category mx-3 pt-7 pb-3"
    :class="$vuetify.breakpoint.smAndUp && 'px-2'"
    :style="{'border-bottom': showBottomBorder ? '1px solid #ccc' : ''}"
  >
    <CategoryHeader>
      {{ category.name }}
    </CategoryHeader>
    <VariableInput
      v-for="variable of category.variables"
      :key="variable._id"
      :variable="variable"
      @updated="$emit('variable-updated')"
    />
    <div v-if="category.meta && category.meta.installationOfTreesImpactInTonnes">
      <p
        class="my-3 mx-3 body-2"
      >
        The installation of the
        <span v-if="version.attributes.totalTrees > 0">
          <strong>{{ version.attributes.totalTrees }} trees</strong>
        </span>
        <span v-if="version.attributes.totalShrubs > 0 && version.attributes.totalTrees > 0">
          and
        </span>
        <span v-if="version.attributes.totalShrubs > 0">
          <strong>{{ version.attributes.totalShrubs }} shrubs</strong>
        </span>
        added to your project will emit
        <strong class="crimson--text">
          {{ category.meta.installationOfTreesImpactInTonnes.toLocaleString() }}kgCOe₂
        </strong>
      </p>
    </div>
    <div v-if="category.meta && category.meta.installationOfForestImpactInTonnes">
      <p
        class="my-3 mx-3 body-2"
      >
        The installation of the
        <span v-if="version.attributes.totalNaturalForest > 0">
          <strong>{{ converter(version.attributes.totalNaturalForest).toFixed() }}  {{ areaMeasureIn }} Natural Forest</strong>
        </span>
        added to your project will emit
        <strong class="crimson--text">
          {{ category.meta.installationOfForestImpactInTonnes.toLocaleString() }}kgCOe₂
        </strong>
      </p>
    </div>
    <CalculatorAssembly
      v-for="assembly of category.assemblies"
      :key="assembly._id"
      :assembly="assembly"
      :is-active="isActive"
      v-on="$listeners"
    />
    <CalculatorElement
      v-for="element of category.elements || []"
      :key="element._id"
      :element="element"
      :is-visible="isActive"
      v-on="$listeners"
    />
  </div>
</template>

<script>
import CalculatorElement from './CalculatorElement'
import VariableInput from './VariableInput'

import { createNamespacedHelpers } from 'vuex'
import CategoryHeader from '@/components/atoms/CategoryHeader'
import CalculatorAssembly from '@/views/calculator/calculatorTab/CalculatorAssembly.vue'
const { mapGetters: mapVersionGetters } = createNamespacedHelpers('projects/versions')
const { mapGetters } = createNamespacedHelpers('elements')

export default {
  name: 'CalculatorCategory',
  components: {
    CalculatorAssembly,
    CalculatorElement,
    CategoryHeader,
    VariableInput
  },
  props: {
    category: {
      type: Object,
      required: true
    },
    isActive: {
      type: Boolean,
      required: true
    },
    showBottomBorder: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapVersionGetters(['version']),
    ...mapGetters(['systemOfMeasurement']),
    areaMeasureIn () {
      return this.systemOfMeasurement === 'imperial' ? 'sf' : 'm2'
    }
  },
  methods: {
    converter (val) {
      return this.systemOfMeasurement === 'imperial' ? val * 10.7639104 : val
    }
  }
}
</script>

<style lang="scss" scoped>
.category-header {
  text-transform: capitalize;
  margin-left: 8px;
  padding-left: 7px;
  border-left: 2px solid var(--v-metal-base);
  // position: relative;
  // &::after {
  //   content: "";
  //   position: absolute;
  //   left: 0;
  //   top: calc(100% + 3px);
  //   width: 300px;
  //   height: 1px;
  //   background-color: var(--v-metal-base);
  // }
}

</style>
