var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var errors = ref.errors;
var invalid = ref.invalid;
return [_c('v-form',{ref:"form",staticClass:"mt-3",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card',[_c('v-card-title',[_vm._v("New EC3-based Custom Material")]),_c('v-card-text',[_c('ValidationProvider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"data-vv-name":"element name","error-messages":errors,"label":"Name","placeholder":"Choose a name for the custom material","required":""},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Category","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"category-selected",attrs:{"data-vv-name":"category","error-messages":errors,"label":"Category","placeholder":"Select a category","items":_vm.categories,"item-value":"name","item-text":"name"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"category-dropdown-item"},[_vm._v(_vm._s(item.name))])]}}],null,true),model:{value:(_vm.formData.category),callback:function ($$v) {_vm.$set(_vm.formData, "category", $$v)},expression:"formData.category"}})]}}],null,true)}),_c('div',{staticClass:"mb-4"},[_c('ValidationProvider',{attrs:{"name":"EC3 UA GWP kgCO₂e per unit:","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"EC3 UA GWP kgCO₂e per unit","error-messages":errors,"items":_vm.gwpPerUnit,"item-key":"title"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('SmartValue',{staticClass:"mr-2",attrs:{"value":item.gwp.value}}),_vm._v(" "+_vm._s(item.gwp.unit.symbol)+" per "+_vm._s(_vm.co2MeasuredPer.symbol)+" : "+_vm._s(item.title)+" ")]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('SmartValue',{staticClass:"mr-2",attrs:{"value":item.gwp.value}}),_vm._v(" "+_vm._s(item.gwp.unit.symbol)+" per "+_vm._s(_vm.co2MeasuredPer.symbol)+" : "+_vm._s(item.title)+" ")]}}],null,true),model:{value:(_vm.selectedGWPPerUnit),callback:function ($$v) {_vm.selectedGWPPerUnit=$$v},expression:"selectedGWPPerUnit"}})]}}],null,true)})],1),_c('ValidationProvider',{attrs:{"name":"Element measured by","rules":{required: true, max_length: 4}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"label":"Element measured by","placeholder":"Select dimensions","hint":"Select up to 3 dimension sets","persistent-hint":"","multiple":"","chips":"","deletable-chips":"","items":_vm.dimensionOptions[_vm.co2MeasuredPerType]},model:{value:(_vm.formData.dimensions),callback:function ($$v) {_vm.$set(_vm.formData, "dimensions", $$v)},expression:"formData.dimensions"}})]}}],null,true)}),(_vm.densityRules.required)?_c('ValidationProvider',{attrs:{"name":"Material Density","rules":_vm.densityRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mr-4",attrs:{"error-messages":errors,"type":"number","data-vv-name":"density","label":"Material density","placeholder":"density","suffix":"kg/m3"},model:{value:(_vm.density),callback:function ($$v) {_vm.density=$$v},expression:"density"}})]}}],null,true)}):_vm._e(),_c('ValidationProvider',{attrs:{"name":"Notes"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"error-messages":errors,"name":"notes","label":"Additional Notes","value":"Notes.","hint":"Hint text","rows":"2"},model:{value:(_vm.formData.notes),callback:function ($$v) {_vm.$set(_vm.formData, "notes", $$v)},expression:"formData.notes"}})]}}],null,true)})],1),(_vm.user.isAdmin)?_c('v-card-text',[_c('div',{staticClass:"mb-4"},[_c('div',{staticClass:"category-name-lis font-weight-bold"},[_c('div',{staticClass:"category-name"},[_vm._v(" "+_vm._s(_vm.ec3SelectedCategory)+" ")]),_c('div',{staticClass:"category-name"},[_vm._v(" "+_vm._s(_vm.ec3SelectedSubcategory)+" ")])]),_c('div',{staticClass:"ml-4 font-italic material-filter"},[_c('div',[_vm._v(" "+_vm._s(_vm.materialFilter)+" ")])])]),_c('v-expansion-panels',[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v(" EC3 Data ")]),_c('v-expansion-panel-content',[_c('JsonPretty',{attrs:{"data":_vm.ec3ResponseView}})],1)],1)],1)],1):_vm._e(),_c('v-card-actions',{staticClass:"py-4 px-5"},[_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" Cancel ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"shamrock white--text","elevation":"0"},on:{"click":function($event){return _vm.$emit('returnToSearchParametersForm')}}},[_vm._v(" Back ")]),_c('v-btn',{attrs:{"color":"shamrock white--text","elevation":"0","disabled":invalid,"type":"submit"}},[_vm._v(" Create Element ")])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }