<template>
  <ValidationObserver
    ref="observer"
    v-slot="{ handleSubmit, errors, invalid }"
  >
    <v-form
      ref="form"
      v-model="valid"
      class="mt-3"
      @submit.prevent="handleSubmit(onSubmit)"
    >
      <v-card>
        <v-card-title>New EC3-based Custom Material</v-card-title>
        <v-card-text>
          <ValidationProvider
            v-slot="{ errors }"
            name="Name"
            rules="required"
          >
            <v-text-field
              v-model="formData.name"
              data-vv-name="element name"
              :error-messages="errors"
              label="Name"
              placeholder="Choose a name for the custom material"
              required
            />
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            name="Category"
            rules="required"
          >
            <v-select
              v-model="formData.category"
              data-vv-name="category"
              class="category-selected"
              :error-messages="errors"
              label="Category"
              placeholder="Select a category"
              :items="categories"
              item-value="name"
              item-text="name"
            >
              <template #item="{ item }">
                <!-- <BrandLogo
                  v-if="item.brand"
                  :brand="item.brand"
                  size="35"
                  class="mr-3"
                  style="background: yellow;"
                /> -->
                <span class="category-dropdown-item">{{ item.name }}</span>
              </template>
            </v-select>
          </ValidationProvider>
          <div class="mb-4">
            <ValidationProvider
              v-slot="{ errors }"
              name="EC3 UA GWP kgCO₂e per unit:"
              rules="required"
            >
              <v-select
                v-model="selectedGWPPerUnit"
                label="EC3 UA GWP kgCO₂e per unit"
                :error-messages="errors"
                :items="gwpPerUnit"
                item-key="title"
              >
                <template #item="{ item }">
                  <SmartValue
                    :value="item.gwp.value"
                    class="mr-2"
                  />
                  {{ item.gwp.unit.symbol }} per {{ co2MeasuredPer.symbol }}
                  : {{ item.title }}
                </template>
                <template #selection="{ item }">
                  <SmartValue
                    :value="item.gwp.value"
                    class="mr-2"
                  />
                  {{ item.gwp.unit.symbol }} per {{ co2MeasuredPer.symbol }}
                  : {{ item.title }}
                </template>
              </v-select>
            </ValidationProvider>
          </div>
          <ValidationProvider
            v-slot="{ errors }"
            name="Element measured by"
            :rules="{required: true, max_length: 4}"
          >
            <v-select
              v-model="formData.dimensions"
              :error-messages="errors"
              label="Element measured by"
              placeholder="Select dimensions"
              hint="Select up to 3 dimension sets"
              persistent-hint
              multiple
              chips
              deletable-chips
              :items="dimensionOptions[co2MeasuredPerType]"
            />
          </ValidationProvider>
          <ValidationProvider
            v-if="densityRules.required"
            v-slot="{ errors }"
            name="Material Density"
            :rules="densityRules"
          >
            <v-text-field
              v-model="density"
              class="mr-4"
              :error-messages="errors"
              type="number"
              data-vv-name="density"
              label="Material density"
              placeholder="density"
              suffix="kg/m3"
            />
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            name="Notes"
          >
            <v-textarea
              v-model="formData.notes"
              :error-messages="errors"
              name="notes"
              label="Additional Notes"
              value="Notes."
              hint="Hint text"
              rows="2"
            />
          </ValidationProvider>
        </v-card-text>
        <v-card-text v-if="user.isAdmin">
          <div class="mb-4">
            <div class="category-name-lis font-weight-bold">
              <div class="category-name">
                {{ ec3SelectedCategory }}
              </div>
              <div class="category-name">
                {{ ec3SelectedSubcategory }}
              </div>
            </div>
            <div class="ml-4 font-italic material-filter">
              <div>
                {{ materialFilter }}
              </div>
            </div>
          </div>
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header>
                EC3 Data
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <JsonPretty :data="ec3ResponseView" />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
        <v-card-actions class="py-4 px-5">
          <v-btn
            color="red"
            text
            @click="$emit('close')"
          >
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn
            color="shamrock white--text"
            elevation="0"
            @click="$emit('returnToSearchParametersForm')"
          >
            Back
          </v-btn>
          <v-btn
            color="shamrock white--text"
            elevation="0"
            :disabled="invalid"
            type="submit"
          >
            Create Element
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </ValidationObserver>
</template>

<script lang="js">

import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { createNamespacedHelpers, mapGetters as mapGlobalGetters } from 'vuex'
import { unitsFromEc3String } from '@/helpers/ec3Helpers'
import SmartValue from '@/components/atoms/SmartValue.vue'
import JsonPretty from 'vue-json-pretty'
import 'vue-json-pretty/lib/styles.css'
import firstLetterUppercase from '@/helpers/firstLetterUppercase'
import getUniqueRef from '@/helpers/getUniqueRef'
// import FormErrors from '@/components/form/FormErrors.vue'

const { mapGetters: mapAuthGetters } = createNamespacedHelpers('auth')
const { mapActions: mapElementActions } = createNamespacedHelpers('elements')

export default {
  name: 'EC3NewCustomMaterial',
  components: {
    // FormErrors,
    JsonPretty,
    ValidationObserver,
    ValidationProvider,
    SmartValue
  },
  props: {
    categories: {
      type: [Array, undefined],
      default: () => []
    },
    ec3SelectedCategory: {
      type: String,
      default: null
    },
    ec3SelectedSubcategory: {
      type: String,
      default: null
    },
    statistics: {
      type: Object,
      default: null
    }
  },
  data () {
    const formData = {
      id: null,
      name: '',
      category: null,
      co2MeasuredPer: null,
      selectedGWPPerUnit: null,
      density: undefined,
      dimensions: []
    }

    return {
      valid: false,
      formData,
      dimensionOptions: {
        volume: [
          'length x width x height',
          'length x width x depth',
          'length x width x thickness',
          'area x depth',
          'volume'
        ],
        weight: [
          'length x width x height',
          'length x width x depth',
          'length x width x thickness',
          'area x depth',
          'weight'
        ],
        count: ['units']
      }
    }
  },
  computed: {
    ...mapGlobalGetters(['unitMappings', 'getEquivalentUnits']),
    ...mapAuthGetters(['user']),
    valueAndUnit () {
      if (this.statistics.material) {
        // note that units are different in the statistical response and the material response
        return unitsFromEc3String(this.statistics?.material?.declared_unit, this.unitMappings)
      } else {
        return unitsFromEc3String(this.statistics?.response?.unit, this.unitMappings)
      }
    },
    selectedGWPPerUnit: {
      get () {
        return this.formData.selectedGWPPerUnit || this.gwpPerUnit?.[0]
      },
      set (val) {
        this.formData.selectedGWPPerUnit = val
      }
    },
    densityRules () {
      const isWeight = this.co2MeasuredPer?.type === 'weight'
      const rules = {
        required: this.formData?.dimensions?.flatMap(str => str.split(' x ').flatMap((descriptor, index) => descriptor !== 'weight' && isWeight)).find(Boolean) || false,
        min_value: 0.0001
      }
      return rules
    },
    gwpPerUnit () {
      let values = []
      if (this.statistics.material) {
        if (this.ec3ResponseView?.gwp_per_category_declared_unit) {
          values.push({ gwp: unitsFromEc3String(this.ec3ResponseView?.gwp_per_category_declared_unit, this.unitMappings), title: 'GWP' })
        }
        if (this.ec3ResponseView?.conservative_estimate) {
          values.push({ gwp: unitsFromEc3String(this.ec3ResponseView?.conservative_estimate, this.unitMappings), title: 'Conservative' })
        }
        if (this.ec3ResponseView?.conservative_estimate) {
          values.push({ gwp: unitsFromEc3String(this.ec3ResponseView?.best_practice, this.unitMappings), title: 'Achievable' })
        }
        values = values.filter((v) => v.gwp?.value)
      } else {
        values = [
          { gwp: unitsFromEc3String(this.ec3ResponseView?.average, this.unitMappings), title: 'Average' },
          { gwp: unitsFromEc3String(this.ec3ResponseView?.best_practice, this.unitMappings), title: 'Achievable' },
          { gwp: unitsFromEc3String(this.ec3ResponseView?.value, this.unitMappings), title: 'Achievable' },
          { gwp: unitsFromEc3String(this.ec3ResponseView?.conservative_estimate, this.unitMappings), title: 'Conservative' }
        ].filter((v) => v.gwp?.value)
      }
      return values
    },
    density: {
      get () {
        if (this.formData.density) {
          return this.formData.density
        } else {
          // there could be a density or there could be a density per unit
          let density = unitsFromEc3String(this.ec3ResponseView?.density, this.unitMappings)
          if (density && density.value && density.value !== 1) return density.value
          density = unitsFromEc3String(this.ec3ResponseView?.mass_per_declared_unit, this.unitMappings)
          if (density.value && density.value !== 1) {
            return density.value
          }
          return undefined
        }
      },
      set (val) {
        this.formData.density = val
      }
    },
    materialFilter () {
      return this.statistics?.materialFilter?.material_filter_str
    },
    ec3ResponseView () {
      if (this.statistics.material) {
        return this.statistics.material
      } else {
        const smallResults = JSON.parse(JSON.stringify(this.statistics.response))
        delete smallResults['materials']
        return smallResults
      }
    },
    co2MeasuredPer () {
      return this.valueAndUnit?.unit || {
        desc: 'units',
        symbol: 'unit',
        type: 'count',
        system: 'metric',
        toSi: 1
      }
    },
    co2MeasuredPerType () {
      return this.co2MeasuredPer?.type || 'count'
    },
    elementData () {
      const { name, category } = this.formData
      const { toSi, type } = this.unitMappings.find(x => x.symbol === this.co2MeasuredPer?.symbol)
      const co2MeasuredPer = this.unitMappings.find(x => x.type === type && x.toSi === 1)?.symbol

      // calculate co2 per unit after si conversion
      const co2PerUnit = this.selectedGWPPerUnit?.gwp.value * toSi

      // get formula
      const formulaForOneTime = `$qty * ${co2PerUnit}`

      const layouts = this.formData.dimensions.map(str => {
        const existingRefs = []
        // in the EC3 case it will be wight or volume
        const layout = {
          dimensions: str.split(' x ').map((descriptor, index) => {
            // FOR EACH DIMENSION:
            // get type
            let type
            if (descriptor === 'units') {
              type = 'count'
            }
            if (!type) {
              const found = this.unitMappings.find(x => x.type === descriptor)
              type = found && found.type
            }
            if (!type) {
              type = 'length'
            }
            // get units
            let units
            if (['width', 'height', 'depth', 'thickness'].includes(descriptor)) { // small units for third dimension (width, height, thickness)
              units = {
                metric: { symbol: 'cm', toSi: 0.01 },
                imperial: { symbol: 'in', toSi: 0.0254 }
              }
            } else {
              units = {}
              const getMetric = () => {
                const { symbol, toSi } = this.unitMappings.find(x => x.type === type && x.toSi === 1)
                return { symbol, toSi }
              }
              units.metric = getMetric()
              const getImperial = () => {
                const { symbol, toSi } = this.getEquivalentUnits(units.metric.symbol)
                return { symbol, toSi }
              }
              units.imperial = getImperial()
            }
            // get label
            const label = firstLetterUppercase(descriptor)
            // get unique ref for formulae
            const ref = getUniqueRef(label, existingRefs)
            existingRefs.push(ref)
            return {
              type,
              units,
              valueRange: type === 'length' ? [0, 100] : undefined,
              value: 0,
              ref,
              label,
              editable: true
            }
          })
        }
        return layout
      })
      return {
        type: 'material',
        ...this.formData, // merge in the old element data
        density: this.density,
        selectedGWPPerUnit: this.selectedGWPPerUnit?.gwp?.value,
        cpdVersion: 3,
        customTemplateSourceType: 'ec3',
        customTemplateSourceBody: {
          source: this.ec3ResponseView,
          actual: {
            selectedGWPPerUnit: this.selectedGWPPerUnit,
            co2MeasuredPer: this.co2MeasuredPer
          }
        },
        customTemplateSourceQuery: {
          ec3SelectedCategory: this.ec3SelectedCategory,
          ec3SelectedSubcategory: this.ec3SelectedSubcategory,
          materialFilter: this.materialFilter
        },
        name,
        category,
        co2MeasuredPer,
        formulaForOneTime,
        layouts,
        co2PerUnit
      }
    }
  },
  methods: {
    ...mapElementActions(['getPredictedLayoutFormula']),
    async onSubmit () {
      // add formulas to each element layout
      const element = { ...this.elementData }
      element.layouts = await Promise.all(element.layouts.map(async layout => {
        const res = await this.getPredictedLayoutFormula({
          dimensions: layout.dimensions,
          co2MeasuredPer: element.co2MeasuredPer
        })
        if (res.error) {
          this.showSnackbar({ color: 'error', text: 'Sorry, there was an error. Please try again..' })
          throw new Error()
        }
        return { ...layout, formula: res.formula }
      }))
      this.$emit('create-element', element)
      this.$refs.observer.reset()
    }
  }
}

</script>
<style scoped>
.category-name {
  display: inline-block;

  &:not(:last-child):after {
    margin-right: 4px;
    content: ">";
  }
}
</style>
