<template>
  <div
    class="mt-4"
    :class="$vuetify.breakpoint.smAndUp && 'px-2 assembly-container pt-4 pb-3'"
  >
    <div
      v-if="showHeader"
      class="header my-1 d-flex justify-end"
    >
      <div class="mr-auto">
        <span
          class="mt-1 subtitle-1 primary--text d-flex align-center"
        >
          {{ assembly.name }}
        </span>
      </div>
      <div class="ml-3">
        <ElementOptions
          :disabled="disabled"
          :deleting="deleting"
          :show-layout-switch="false"
          @delete-element="deleteAssembly"
        >
          <template v-slot:delete-text>
            Delete Assembly
          </template>
        </ElementOptions>
      </div>
    </div>
    <CalculatorElement
      v-for="element of assembly.elements"
      :key="element._id"
      :element="element"
      :is-visible="isActive"
      v-on="$listeners"
    />
    <div
      v-for="category of assembly.categories"
      :key="category._id"
      class="mt-4"
    >
      {{ category.name }}
      <CalculatorElement
        v-for="element of category.elements"
        :key="element._id"
        :element="element"
        :is-visible="isActive"
        v-on="$listeners"
      />
    </div>
    <ElementImpact
      :categories="assembly.categories"
    >
      <template v-slot:prepend>
        Total Emissions:
      </template>
    </ElementImpact>
  </div>
</template>

<script>
import CalculatorElement from './CalculatorElement'

import { createNamespacedHelpers, mapActions } from 'vuex'
import ElementImpact from '@/components/application/element/ElementImpact.vue'
import ElementOptions from '@/components/application/element/ElementOptions.vue'
const { mapGetters: mapVersionGetters } = createNamespacedHelpers('projects/versions')
const { mapGetters } = createNamespacedHelpers('elements')

export default {
  name: 'CalculatorAssembly',
  components: {
    ElementOptions,
    ElementImpact,
    CalculatorElement
  },
  props: {
    assembly: {
      type: Object,
      required: true
    },
    isActive: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      deleting: false,
      disabled: false,
      showHeader: true
    }
  },
  computed: {
    ...mapVersionGetters(['version, statsLoading', 'formDisabled']),
    ...mapGetters(['systemOfMeasurement'])
  },
  watch: {
    formDisabled (val) {
      this.disabled = val
    }
  },
  methods: {
    ...mapActions(['showSnackbar']),
    converter (val) {
      return this.systemOfMeasurement === 'imperial' ? val * 10.7639104 : val
    },
    async deleteAssembly () {
      this.deleting = true
      try {
        await this.$axios.delete(`/assemblies/${this.assembly._id}`)
        this.showSnackbar(`Successfully deleted Assembly "${this.assembly.name}"`)
        this.$emit('assembly-deleted', this.assembly._id)
        this.deleting = false
      } catch (err) {
        this.showSnackbar({ color: 'error', text: 'Unable to delete assembly. Please refresh the page and try again.' })
        this.deleting = false
        throw err
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.assembly-container {
  margin-bottom: 20px;
  padding: 5px 13px;
  border: 2px solid rgba(0, 0, 0, 0);
  border-radius: 6px;
  background: #EBEBEB;
  position: relative;
}
.category-header {
  text-transform: capitalize;
  margin-left: 8px;
  padding-left: 7px;
  border-left: 2px solid var(--v-metal-base);
  // position: relative;
  // &::after {
  //   content: "";
  //   position: absolute;
  //   left: 0;
  //   top: calc(100% + 3px);
  //   width: 300px;
  //   height: 1px;
  //   background-color: var(--v-metal-base);
  // }
}

</style>
