var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',{staticClass:"condition-row"},[_c('th',[_c('v-tooltip',{attrs:{"top":"","max-width":"300px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('div',_vm._g({},Object.assign({}, tooltip)),[_c('v-checkbox',{staticClass:"edit-checkbox",attrs:{"color":"cpdblue","on-icon":'mdi-pencil-outline',"off-icon":'mdi-pencil-off-outline'},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.sectionName)+" "),_c('div',{staticClass:"row-handle align-center ml-3"},[_c('v-icon',{attrs:{"color":"cpdblue","size":"medium"}},[_vm._v(" mdi-information-variant-circle-outline ")])],1)]},proxy:true}],null,true),model:{value:(_vm.readonly),callback:function ($$v) {_vm.readonly=$$v},expression:"readonly"}})],1)]}}])},[_c('div',[_vm._t("tooltip",function(){return [_c('v-spacer')]})],2)])],1),(!_vm.showQuantity)?_c('th',{staticClass:"text-capitalize"},[_c('v-tooltip',{attrs:{"top":"","max-width":"300px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"row-handle align-center"},on),[_c('v-icon',{attrs:{"color":"cpdblue","size":"medium"}},[_vm._v(" mdi-information-variant-circle-outline ")]),_vm._v(" "+_vm._s(_vm.enterSiteInformationBy)+" ")],1)]}}],null,false,519659447)},[_c('div',[(_vm.enterSiteInformationBy === 'area')?_c('span',[_vm._t("area-tooltip",function(){return [_vm._v(" Sum of all planted areas plus hardscape and building area must equal overall site area. ")]})],2):_c('span',[_vm._t("percentage-tooltip",function(){return [_vm._v(" Percentage of all planted areas must equal 100%. ")]})],2)])])],1):_vm._e(),(_vm.showQuantity)?_c('th',{staticClass:"text-capitalize"},[_vm._v(" Quantity ")]):_vm._e(),(_vm.showPercentNative)?_c('th',{staticClass:"text-capitalize"},[_c('v-tooltip',{attrs:{"top":"","max-width":"300px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"row-handle align-center"},on),[_c('v-icon',{attrs:{"color":"cpdblue","size":"medium"}},[_vm._v(" mdi-information-variant-circle-outline ")]),_vm._v(" % Native ")],1)]}}],null,false,3350614314)},[_vm._t("native-tooltip")],2)],1):_vm._e(),(_vm.showCondition)?_c('th',{staticClass:"text-capitalize"},[_c('v-tooltip',{attrs:{"top":"","max-width":"300px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_vm._v(" Condition ")])]}}],null,false,4248974582)},[_vm._t("condition-tooltip")],2)],1):_vm._e()])]),_c('tbody',[_vm._l((_vm.vModel),function(row){return [_c('SiteConditionRow',{key:row.cpid,attrs:{"v-model":row,"available-area":_vm.availableArea,"readonly":_vm.readonly,"show-quantity":_vm.showQuantity,"show-percent-native":_vm.showPercentNative,"show-condition":_vm.showCondition,"rules":_vm.rules,"enter-site-information-by":_vm.enterSiteInformationBy},on:{"input":_vm.input}})]})],2)]},proxy:true}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }