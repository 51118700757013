<template>
  <div>
    <v-dialog
      v-model="modalVisible"
      width="600"
      :fullscreen="currentPage === 'searchForSpecificEPD'"
      @click:outside="handleClose"
      @keydown.esc="handleClose"
    >
      <div v-if="currentPage === 'searchParametersForm' && modalVisible">
        <EC3SearchParametersForm
          :search-parameters="searchParameters"
          @close="handleClose"
          @newCustomMaterial="newCustomMaterial"
          @searchForSpecificEPD="searchForSpecificEPD"
        />
      </div>
      <div v-if="currentPage === 'searchForSpecificEPD' && modalVisible">
        <EC3SearchForSpecificEPD
          :statistics="statistics"
          :search-parameters="searchParameters"
          :ec3-selected-category="ec3SelectedCategory"
          :ec3-selected-subcategory="ec3SelectedSubcategory"
          @close="handleClose"
          @newCustomMaterial="newCustomMaterial"
          @returnToSearchParametersForm="returnToSearchParametersForm"
        />
      </div>
      <div v-if="currentPage === 'newCustomMaterial' && modalVisible">
        <EC3NewCustomMaterial
          :statistics="statistics"
          :ec3-selected-category="ec3SelectedCategory"
          :ec3-selected-subcategory="ec3SelectedSubcategory"
          :category="category"
          :categories="categories"
          @close="handleClose"
          @create-element="createCustomMaterial"
          @returnToSearchParametersForm="returnToSearchParametersForm"
        />
      </div>
    </v-dialog>
  </div>
</template>

<script>

import EC3SearchParametersForm from './EC3SearchParametersForm'
import EC3NewCustomMaterial from './EC3NewCustomMaterial'
import EC3SearchForSpecificEPD from './EC3SearchForSpecificEPD'

export default {
  name: 'EC3MaterialWorkflow',
  components: {
    EC3SearchParametersForm,
    EC3SearchForSpecificEPD,
    EC3NewCustomMaterial
  },
  props: {
    category: {
      type: String,
      default: null
    },
    categories: {
      type: [Array, undefined],
      default: () => []
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      currentPage: 'searchParametersForm',
      statistics: null,
      searchParameters: null,
      modalVisible: this.visible
    }
  },
  computed: {
    ec3SelectedCategory () {
      return this.searchParameters?.ec3SelectedCategory
    },
    ec3SelectedSubcategory () {
      return this.searchParameters?.ec3SelectedSubcategory
    }
  },
  watch: {
    visible (val) {
      this.modalVisible = val
    }
  },
  methods: {
    handleClose () {
      this.currentPage = 'searchParametersForm'
      this.statistics = null
      this.searchParameters = null
      this.$emit('close')
    },
    newCustomMaterial ({ statistics, searchParameters }) {
      this.searchParameters = searchParameters
      this.currentPage = 'newCustomMaterial'
      this.statistics = statistics
    },
    returnToSearchParametersForm () {
      this.currentPage = 'searchParametersForm'
    },
    searchForSpecificEPD ({ statistics, searchParameters }) {
      this.searchParameters = searchParameters
      this.currentPage = 'searchForSpecificEPD'
      this.statistics = statistics
    },
    createCustomMaterial (element) {
      this.$emit('create-element', element)
      this.handleClose()
    }
  }
}
</script>
