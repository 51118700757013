<template>
  <ValidationObserver
    ref="observer"
    v-slot="{ handleSubmit, errors, invalid }"
  >
    <v-form
      ref="form"
      v-model="formData"
      class="mt-3"
      @submit.prevent="() => null"
    >
      <v-card
        max-width="800"
        class="mx-auto"
      >
        <v-card-title>EC3 Database Search</v-card-title>
        <v-container>
          <v-row dense>
            <v-col cols="12">
              <v-card>
                <v-card-text>
                  <v-row>
                    <v-col cols="4">
                      Location
                    </v-col>
                    <v-col>
                      <v-autocomplete
                        v-model="ec3SelectedJurisdiction"
                        label="Country or Region"
                        :items="regionCodes"
                        item-value="Code"
                        item-text="Country or Area"
                        placeholder="Select an Area or Region"
                        :hide-details="true"
                        multiple
                        deletable-chips
                        dense
                        chips
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4">
                      Category
                    </v-col>
                    <v-col>
                      <v-select
                        v-model="ec3SelectedCategory"
                        data-vv-name="subcategory"
                        class="category-selected"
                        placeholder="Select a Category"
                        :hide-details="true"
                        dense
                        :items="categoryNames"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4">
                      Subcategory
                    </v-col>
                    <v-col>
                      <v-select
                        v-model="ec3SelectedSubcategory"
                        :disabled="!ec3SelectedCategory"
                        data-vv-name="subcategory"
                        class="category-selected"
                        dense
                        placeholder="Select a subcategory"
                        :hide-details="true"
                        :items="subcategoryNames"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row
            v-if="ec3SelectedSubcategory"
            dense
          >
            <v-col cols="12">
              <v-expansion-panels flat>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    Search Options
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div class="jsf-body">
                      <v-jsf
                        v-if="materialSchema"
                        v-model="jsonFormData"
                        :schema="materialSchema.general"
                      />
                      <v-expansion-panels
                        v-if="materialSchema.advanced"
                        flat
                        class="no-margin-panels"
                      >
                        <v-expansion-panel
                          v-if="materialSchema.advanced"
                        >
                          <v-expansion-panel-header>
                            Advanced Options
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <v-jsf
                              v-if="materialSchema"
                              v-model="jsonFormData"
                              :schema="materialSchema.advanced"
                            />
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <div class="text-right mr-4">
                <v-btn
                  color="shamrock white--text"
                  small
                  elevation="0"
                  :loading="searchingMaterial"
                  :disabled="invalid"
                  @click="queryStatistics"
                >
                  Search EC3
                </v-btn>
              </div>
            </v-col>
          </v-row>
          <v-row
            v-if="selectedStatistics || searchingMaterial"
            class="my-4"
            dense
          >
            <v-col cols="12">
              <v-card
                :loading="searchingMaterial"
                :disabled="searchingMaterial"
              >
                <v-card-title>Results</v-card-title>
                <v-card-text v-if="selectedStatistics">
                  <div> Product EPDs: <SmartValue :value="productEPDs" /> </div>
                  <div> Industry EPDs: <SmartValue :value="industryEPDs" /> </div>
                  <div>
                    Achievable: <SmartValue :value="achievable.value" /> {{ achievable.unit }}
                    <InfoDialog
                      title="Achievable Value Info"
                    >
                      20th percentile of all Product EPDs and Industry EPD data points matching search terms above.
                      Each Industry EPD is assumed to represent 20 data points with a normal distribution around
                      the reported value. In a list of 1000 EPDs plus Industry-EPD data points, the 200th lowest
                      one would be the 20th percentile.
                    </InfoDialog>
                  </div>
                  <div>
                    Average: <SmartValue :value="average.value" /> {{ average.unit }}
                    <InfoDialog
                      title="Average Value Info"
                    >
                      Arithmetic Mean and Relative Standard Deviation of all Product EPDs and Industry EPD data points
                      matching search terms above.  Each Industry EPD is assumed to represent 20 data points with a normal
                      distribution around the reported value.
                    </InfoDialog>
                  </div>
                  <div>
                    Conservative: <SmartValue :value="conservative.value" /> {{ conservative.unit }}
                    <InfoDialog
                      title="Conservative Value Info"
                    >
                      80th percentile of all Product EPDs and Industry EPD data points matching search terms above.  Each
                      Industry EPD is assumed to represent 20 data points with a normal distribution around the reported
                      value.  In a list of 1000 EPDs plus Industry-EPD data points, the 200th highest one would be the
                      80th percentile.
                    </InfoDialog>
                  </div>
                  <div> Unit: {{ conservedPerUnit }} </div>
                  <div v-if="density">
                    Density: <SmartValue :value="density.value" /> {{ density.unit }}
                  </div>
                </v-card-text>
                <v-card-actions class="py-4 px-5 d-flex justify-center">
                  <v-btn
                    color="shamrock white--text"
                    small
                    elevation="0"
                    :disabled="searchingMaterial || searchingMaterial || invalid"
                    @click="searchForSpecificEPD"
                  >
                    Search for specific EPD
                  </v-btn>
                  <v-btn
                    color="shamrock white--text"
                    small
                    elevation="0"
                    :disabled="searchingMaterial || searchingMaterial || invalid"
                    @click="customMaterial"
                  >
                    Apply to custom material
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions class="px-5">
          <v-btn
            color="red"
            text
            @click="handleClose"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </ValidationObserver>
</template>
<script lang="js">

import { createNamespacedHelpers, mapGetters as mapGlobalGetters, mapActions as mapGlobalActions } from 'vuex'
import VJsf from '@koumoul/vjsf/lib/VJsf.js'

import SmartValue from '@/components/atoms/SmartValue.vue'
import { ValidationObserver } from 'vee-validate'
import 'vue-json-pretty/lib/styles.css'
import { unitsFromEc3String } from '@/helpers/ec3Helpers'
import InfoDialog from '@/components/modals/InfoDialog.vue'
const { mapGetters: mapProjectGetters } = createNamespacedHelpers('projects')

export default {
  name: 'EC3SearchParametersForm',
  components: {
    InfoDialog,
    SmartValue,
    ValidationObserver,
    VJsf
  },
  props: {
    searchParameters: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      categories: {},
      achievableInfoDialog: false,
      ec3SelectedDistance: 20,
      ec3SelectedRegion: null,
      ec3SelectedJurisdiction: null,
      ec3SelectedCategory: this.searchParameters?.ec3SelectedCategory || null,
      ec3SelectedSubcategory: this.searchParameters?.ec3SelectedSubcategory || null,
      jsonFormData: this.searchParameters?.jsonFormData || {},
      selectedStatistics: null,
      formData: null,
      loadingMaterial: false,
      searchingMaterial: false
    }
  },
  computed: {
    ...mapGlobalGetters(['ec3Configuration', 'unitMappings', 'regionCodes']),
    ...mapProjectGetters(['project']),

    categoryNames () {
      return Object.keys(this.jsonSchema)
    },
    subcategoryNames () {
      const subcategories = this.jsonSchema?.[this.ec3SelectedCategory] || {}
      return Object.keys(subcategories)
    },
    materialQuery () {
      return {
        materialFilter: { ...this.jsonFormData, ...this.jurisdictionFilter },
        category: this.ec3SelectedSubcategory,
        ec3SelectedSubcategory: this.ec3SelectedSubcategory
      }
    },
    materialSchema () {
      if (this.ec3SelectedCategory && this.ec3SelectedSubcategory) {
        return this.jsonSchema[this.ec3SelectedCategory]?.[this.ec3SelectedSubcategory]
      }
      return null
    },
    productEPDs () {
      return this.selectedStatistics?.response?.samples
    },
    industryEPDs () {
      return this.selectedStatistics?.response?.industry_epds_count
    },
    density () {
      // there could be a density or there could be a density per unit
      let density = this.simpleUnitValue(unitsFromEc3String(this.selectedStatistics?.response?.density, this.unitMappings))
      if (density && density.value && density.value !== 1) return density
      density = unitsFromEc3String(this.statistics?.response?.mass_per_declared_unit, this.unitMappings)
      if (density.value && density.value !== 1) {
        return density.value
      }
      return undefined
    },
    achievable () {
      return this.simpleUnitValue(unitsFromEc3String(this.selectedStatistics?.response?.best_practice, this.unitMappings))
    },
    average () {
      return this.simpleUnitValue(unitsFromEc3String(this.selectedStatistics?.response?.average, this.unitMappings))
    },
    conservative () {
      return this.simpleUnitValue(unitsFromEc3String(this.selectedStatistics?.response?.conservative_estimate, this.unitMappings))
    },
    conservedPerUnit () {
      return this.selectedStatistics?.response?.unit
    },
    jurisdictionFilter () {
      if (this.ec3SelectedJurisdiction) {
        return { jurisdiction: this.ec3SelectedJurisdiction }
      }
      return {}
    }
  },
  watch: {
    ec3SelectedCategory () {
      this.ec3SelectedSubcategory = null
      this.clearForm()
    },
    ec3SelectedSubcategory () {
      this.clearForm()
    }
  },
  created () {
    //    const { data } = await this.$axios.get('/ec3/categories')
    this.jsonSchema = this.ec3Configuration.jsonSchema
    this.jsonUISchema = this.ec3Configuration.jsonUISchema
  },
  methods: {
    ...mapGlobalActions(['showSnackbar']),
    clearForm () {
      this.selectedStatistics = null
      this.jsonFormData = {}
    },
    handleClose () {
      this.categories = {}
      this.ec3SelectedCategory = null
      this.ec3SelectedSubcategory = null
      this.ec3SelectedJurisdiction = null
      this.jsonFormData = {}
      this.selectedStatistics = null
      this.valid = null
      this.loadingMaterial = false
      this.searchingMaterial = false
      this.$emit('close')
    },
    async queryStatistics () {
      this.searchingMaterial = true
      const query = (await this.$axios.post('ec3/statistics?sample=true', this.materialQuery))
      if (query.status === 200) {
        this.selectedStatistics = query.data
      } else {
        await this.showSnackbar({ color: 'error', text: 'Whoops, an error occurred while fetching from EC3. Please try again.' })
      }
      this.searchingMaterial = false
    },
    async searchForSpecificEPD () {
      this.$emit('searchForSpecificEPD', {
        statistics: this.selectedStatistics,
        searchParameters: {
          materialQuery: this.materialQuery,
          ec3SelectedCategory: this.ec3SelectedCategory,
          ec3SelectedSubcategory: this.ec3SelectedSubcategory,
          ec3SelectedJurisdiction: this.ec3SelectedJurisdiction

        }
      })
    },
    async customMaterial () {
      this.$emit('newCustomMaterial', {
        statistics: this.selectedStatistics,
        searchParameters: {
          ec3SelectedCategory: this.ec3SelectedCategory,
          ec3SelectedSubcategory: this.ec3SelectedSubcategory
        }
      })
    },
    simpleUnitValue (unitValue) {
      return {
        value: unitValue.value,
        unit: unitValue.unit?.symbol
      }
    }
  }
}
</script>

<style scoped>
::v-deep .v-input__icon.v-input__icon--clear {
  display: none;
}

::v-deep .jsf-body .col {
  padding: 3px !important;
}

::v-deep .field-op label.v-label {
  display: none;
}

.category-name {
  display: inline-block;

  &:not(:last-child):after {
    margin-right: 4px;
    content: ">";
  }
}

/* Remove left and right margins from the expansion panels */
.no-margin-panels .v-expansion-panel-header {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
}
.sentence-with-input {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.inline-input {
  width: 100px;
  margin: 0 8px;
  padding-top: 0;
}

/* Remove the spinning arrows for number input if desired */
::v-deep .inline-input >>> input::-webkit-outer-spin-button,
::v-deep.inline-input >>> input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Firefox */
::v-deep .inline-input >>> input[type=number] {
  -moz-appearance: textfield;
}
</style>
