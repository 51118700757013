var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var errors = ref.errors;
var invalid = ref.invalid;
return [_c('v-form',{staticClass:"mt-3",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.element ? 'Update' : 'New')+" custom material")]),_c('v-card-text',[_c('ValidationProvider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"data-vv-name":"element name","error-messages":errors,"label":"Name","placeholder":"Choose a name for the custom material","required":""},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Category","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"category-selected",attrs:{"data-vv-name":"category","error-messages":errors,"label":"Category","placeholder":"Select a category","items":_vm.categories,"item-value":"name","item-text":"name"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"category-dropdown-item"},[_vm._v(_vm._s(item.name))])]}}],null,true),model:{value:(_vm.formData.category),callback:function ($$v) {_vm.$set(_vm.formData, "category", $$v)},expression:"formData.category"}})]}}],null,true)}),_c('div',{staticClass:"d-flex mt-2"},[_c('ValidationProvider',{attrs:{"name":"CO₂e per unit","rules":"required|min_value:0.00001"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"pr-2",attrs:{"type":"number","data-vv-name":"CO₂e per unit","error-messages":errors,"label":"CO₂e per unit","placeholder":" ","suffix":"kgCO₂e"},model:{value:(_vm.co2PerUserUnit),callback:function ($$v) {_vm.co2PerUserUnit=$$v},expression:"co2PerUserUnit"}})]}}],null,true)}),_c('v-input',{scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('span',{staticClass:"d-block text-xs-center mt-5"},[_vm._v("per")])]},proxy:true}],null,true)},[_c('ValidationProvider',{attrs:{"name":"CO₂e measure by","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"disabled":!!_vm.element,"data-vv-name":"co2 measured by","error-messages":errors,"items":_vm.validUnits},model:{value:(_vm.formData.co2MeasuredPerUser),callback:function ($$v) {_vm.$set(_vm.formData, "co2MeasuredPerUser", $$v)},expression:"formData.co2MeasuredPerUser"}})]}}],null,true)})],1)],1),_c('ValidationProvider',{attrs:{"name":"Element measured by","rules":{required: true, max_length: 3}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"disabled":!!_vm.element,"error-messages":errors,"label":"Element measured by","placeholder":"Select dimensions","hint":"Select up to 3 dimension sets","persistent-hint":"","multiple":"","chips":"","deletable-chips":"","items":_vm.dimensionOptions[_vm.co2MeasuredPerType]},model:{value:(_vm.formData.dimensions),callback:function ($$v) {_vm.$set(_vm.formData, "dimensions", $$v)},expression:"formData.dimensions"}})]}}],null,true)}),(_vm.co2MeasuredPerType === 'weight' && (_vm.formData.dimensions[0] !== 'weight' || _vm.formData.dimensions.length > 1))?_c('div',{staticClass:"d-flex"},[_c('ValidationProvider',{attrs:{"name":"Element Density","rules":"required|min_value:0.0001"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mr-4",attrs:{"type":"number","data-vv-name":"density","error-messages":errors,"label":"Element density","placeholder":"density","suffix":"kg/m3"},model:{value:(_vm.formData.density),callback:function ($$v) {_vm.$set(_vm.formData, "density", $$v)},expression:"formData.density"}}),_c('FormErrors',{attrs:{"errors":"errors"}})]}}],null,true)})],1):_vm._e(),(_vm.element)?_c('div',{staticClass:"ma-4 mb-0",staticStyle:{"text-align":"center"}},[_c('b',[_vm._v("Edits made to existing custom elements will be reflected across all project instances.")])]):_vm._e()],1),_c('v-card-actions',{staticClass:"py-4 px-5"},[_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" Cancel ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"shamrock white--text","disabled":invalid,"elevation":"0","type":"submit"}},[_vm._v(" "+_vm._s(_vm.element ? 'Update' : 'Create')+" element ")])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }